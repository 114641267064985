import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// Switch is now Routes

import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Layout from './components/Layout/Layout'
import Enrollment from './pages/Enrollment/Enrollment'

import SignupEntrepreneurshipOnlineMonthly from './pages/SignupEntrepreneurshipOnlineMonthly/SignupEntrepreneurshipOnlineMonthly';
import SignupEntrepreneurshipOnlineQuarterly from './pages/SignupEntrepreneurshipOnlineQuarterly/SignupEntrepreneurshipOnlineQuarterly'
import SignupEntrepreneurshipTechnologyOnlineMonthly from './pages/SignupEntrepreneurshipTechnologyOnlineMonthly/SignupEntrepreneurshipTechnologyOnlineMonthly';
import SignupEntrepreneurshipTechnologyOnlineQuarterly from './pages/SignupEntrepreneurshipTechnologyOnlineQuarterly/SignupEntrepreneurshipTechnologyOnlineQuarterly';
import SignupEnglishOnlineMonthly from './pages/SignupEnglishOnlineMonthly/SignupEnglishOnlineMonthly'
import SignupEnglishOnlineQuarterly from './pages/SignupEnglishOnlineQuarterly/SignupEnglishOnlineQuarterly'
import SignupEntrepreneurshipTechnologyEnglishOnlineMonthly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly'
import SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly'
import SignupEntrepreneurshipPresentialMonthly from './pages/SignupEntrepreneurshipPresentialMonthly/SignupEntrepreneurshipPresentialMonthly';
import SignupEntrepreneurshipPresentialQuarterly from './pages/SignupEntrepreneurshipPresentialQuarterly/SignupEntrepreneurshipPresentialQuarterly'
import SignupEntrepreneurshipTechnologyPresentialMonthly from './pages/SignupEntrepreneurshipTechnologyPresentialMonthly/SignupEntrepreneurshipTechnologyPresentialMonthly';
import SignupEntrepreneurshipTechnologyPresentialQuarterly from './pages/SignupEntrepreneurshipTechnologyPresentialQuarterly/SignupEntrepreneurshipTechnologyPresentialQuarterly';
import SignupEnglishPresentialMonthly from './pages/SignupEnglishPresentialMonthly/SignupEnglishPresentialMonthly'
import SignupEnglishPresentialQuarterly from './pages/SignupEnglishPresentialQuarterly/SignupEnglishPresentialQuarterly'
import SignupEntrepreneurshipTechnologyEnglishPresentialMonthly from './pages/SignupEntrepreneurshipTechnologyEnglishPresentialMonthly/SignupEntrepreneurshipTechnologyEnglishPresentialMonthly'
import SignupEntrepreneurshipTechnologyEnglishPresentialQuarterly from './pages/SignupEntrepreneurshipTechnologyEnglishPresentialQuarterly/SignupEntrepreneurshipTechnologyEnglishPresentialQuarterly'
import SignupBusinessCampWeek from './pages/SignupBusinessCampWeek/SignupBusinessCampWeek';
import SignupBusinessCampBiweek from './pages/SignupBusinessCampBiweek/SignupBusinessCampBiweek';
import SignupBusinessCampMonth from './pages/SignupBusinessCampMonth/SignupBusinessCampMonth';
import SignupNFTClass from './pages/SignupNFTClass/SignupNFTClass.';
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Building from './pages/Building/Building'
import ImageLoader from './components/ImageLoader/ImageLoader';
// import logo from './logo.svg';
// import './App.css';
import './pages/HomePage/HomePage.scss'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="*" element={<Building />}></Route> */}
          <Route element={<Layout />} >
            <Route exact path='/' element={<Enrollment/>} />
            {/* <Route exact path="/plans/:id" element={<Plans />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-technology/:id" element={<SignupEntrepreneurshipTechnology />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-trimestral" element={<SignupEntrepreneurshipTrimestral />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-trimestral/:id" element={<SignupEntrepreneurshipTrimestral />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral/:id" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}

            {/* <Route
            exact
            path="/sign-up-entrepreneurship-technology-online-monthly"
            element={<SignupEntrepreneurshipTechnologyOnlineMonthly />} /> */}
            <Route
            exact
            path="/sign-up-entrepreneurship-technology-online-monthly"
            element={<Building />} />
            {/* <Route
            exact
            path="/sign-up-entrepreneurship-technology-online-quarterly"
            element={<SignupEntrepreneurshipTechnologyOnlineQuarterly />} /> */}
            <Route
            exact
            path="/sign-up-entrepreneurship-technology-online-quarterly"
            element={<Building />} />
            {/* <Route
            exact
            path="/sign-up-english-online-monthly"
            element={<SignupEnglishOnlineMonthly />} /> */}
            <Route
            exact
            path="/sign-up-english-online-monthly"
            element={<Building />} />
            {/* <Route
            exact
            path="/sign-up-english-online-quarterly"
            element={<SignupEnglishOnlineQuarterly />} /> */}
            <Route
            exact
            path="/sign-up-english-online-quarterly"
            element={<Building />} />
            {/* <Route
            exact
            path="/sign-up-entrepreneurship-technology-english-online-monthly"
            element={<SignupEntrepreneurshipTechnologyEnglishOnlineMonthly />} /> */}
            <Route
            exact
            path="/sign-up-entrepreneurship-technology-english-online-monthly"
            element={<Building />} />
            {/* <Route
            exact
            path="/sign-up-entrepreneurship-technology-english-online-quarterly"
            element={<SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly />} /> */}
            <Route
            exact
            path="/sign-up-entrepreneurship-technology-english-online-quarterly"
            element={<Building />} />

            <Route exact path="/building" element={<Building />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>


    // </Route>
    
    // <AuthProvider>
    //   <BrowserRouter>
    //     <Switch>
    //       <Route exact path="/" component={Home}></Route>
    //     </Switch>
    //   </BrowserRouter>
    // </AuthProvider>
  );
}

export default App;
