import React from 'react';
import { createRoot } from 'react-dom/client'

import './assets/styles/base.scss'
import App from './App';
// import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById('root')).render(
  <React.StrictMode >
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// Disabled below
// serviceWorker.unregister();
